exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-extra-hoodie-js": () => import("./../../../src/pages/extra-hoodie.js" /* webpackChunkName: "component---src-pages-extra-hoodie-js" */),
  "component---src-pages-faqs-js": () => import("./../../../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-hoodie-js": () => import("./../../../src/pages/hoodie.js" /* webpackChunkName: "component---src-pages-hoodie-js" */),
  "component---src-pages-hoodies-for-charity-js": () => import("./../../../src/pages/hoodies-for-charity.js" /* webpackChunkName: "component---src-pages-hoodies-for-charity-js" */),
  "component---src-pages-hoodies-for-charity-thanks-js": () => import("./../../../src/pages/hoodies-for-charity/thanks.js" /* webpackChunkName: "component---src-pages-hoodies-for-charity-thanks-js" */),
  "component---src-pages-how-to-order-js": () => import("./../../../src/pages/how-to-order.js" /* webpackChunkName: "component---src-pages-how-to-order-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-leavers-hoodies-ashley-down-primary-school-t-shirts-2024-js": () => import("./../../../src/pages/leavers-hoodies/ashley-down-primary-school-t-shirts-2024.js" /* webpackChunkName: "component---src-pages-leavers-hoodies-ashley-down-primary-school-t-shirts-2024-js" */),
  "component---src-pages-leavers-hoodies-best-designs-js": () => import("./../../../src/pages/leavers-hoodies/best-designs.js" /* webpackChunkName: "component---src-pages-leavers-hoodies-best-designs-js" */),
  "component---src-pages-leavers-hoodies-faqs-js": () => import("./../../../src/pages/leavers-hoodies/faqs.js" /* webpackChunkName: "component---src-pages-leavers-hoodies-faqs-js" */),
  "component---src-pages-leavers-hoodies-grenoside-community-primary-school-t-shirts-24-js": () => import("./../../../src/pages/leavers-hoodies/grenoside-community-primary-school-t-shirts-24.js" /* webpackChunkName: "component---src-pages-leavers-hoodies-grenoside-community-primary-school-t-shirts-24-js" */),
  "component---src-pages-leavers-hoodies-grenoside-community-primary-school-t-shirts-25-js": () => import("./../../../src/pages/leavers-hoodies/grenoside-community-primary-school-t-shirts-25.js" /* webpackChunkName: "component---src-pages-leavers-hoodies-grenoside-community-primary-school-t-shirts-25-js" */),
  "component---src-pages-leavers-hoodies-higham-primary-school-t-shirts-2024-js": () => import("./../../../src/pages/leavers-hoodies/higham-primary-school-t-shirts-2024.js" /* webpackChunkName: "component---src-pages-leavers-hoodies-higham-primary-school-t-shirts-2024-js" */),
  "component---src-pages-leavers-hoodies-intake-primary-school-t-shirts-2024-js": () => import("./../../../src/pages/leavers-hoodies/intake-primary-school-t-shirts-2024.js" /* webpackChunkName: "component---src-pages-leavers-hoodies-intake-primary-school-t-shirts-2024-js" */),
  "component---src-pages-leavers-hoodies-js": () => import("./../../../src/pages/leavers-hoodies.js" /* webpackChunkName: "component---src-pages-leavers-hoodies-js" */),
  "component---src-pages-leavers-hoodies-maypole-primary-school-t-shirts-2025-js": () => import("./../../../src/pages/leavers-hoodies/maypole-primary-school-t-shirts-2025.js" /* webpackChunkName: "component---src-pages-leavers-hoodies-maypole-primary-school-t-shirts-2025-js" */),
  "component---src-pages-leavers-hoodies-order-complete-js": () => import("./../../../src/pages/leavers-hoodies/order-complete.js" /* webpackChunkName: "component---src-pages-leavers-hoodies-order-complete-js" */),
  "component---src-pages-leavers-hoodies-thanks-js": () => import("./../../../src/pages/leavers-hoodies/thanks.js" /* webpackChunkName: "component---src-pages-leavers-hoodies-thanks-js" */),
  "component---src-pages-missed-deadline-js": () => import("./../../../src/pages/missed-deadline.js" /* webpackChunkName: "component---src-pages-missed-deadline-js" */),
  "component---src-pages-online-shops-js": () => import("./../../../src/pages/online-shops.js" /* webpackChunkName: "component---src-pages-online-shops-js" */),
  "component---src-pages-order-complete-js": () => import("./../../../src/pages/order-complete.js" /* webpackChunkName: "component---src-pages-order-complete-js" */),
  "component---src-pages-printed-face-masks-js": () => import("./../../../src/pages/printed-face-masks.js" /* webpackChunkName: "component---src-pages-printed-face-masks-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-request-a-callback-js": () => import("./../../../src/pages/request-a-callback.js" /* webpackChunkName: "component---src-pages-request-a-callback-js" */),
  "component---src-pages-request-a-callback-thanks-js": () => import("./../../../src/pages/request-a-callback/thanks.js" /* webpackChunkName: "component---src-pages-request-a-callback-thanks-js" */),
  "component---src-pages-reviews-js": () => import("./../../../src/pages/reviews.js" /* webpackChunkName: "component---src-pages-reviews-js" */),
  "component---src-pages-shop-closed-js": () => import("./../../../src/pages/shop-closed.js" /* webpackChunkName: "component---src-pages-shop-closed-js" */),
  "component---src-pages-shop-opsoc-made-in-dagenham-js": () => import("./../../../src/pages/shop/opsoc-made-in-dagenham.js" /* webpackChunkName: "component---src-pages-shop-opsoc-made-in-dagenham-js" */),
  "component---src-pages-ski-hoodies-js": () => import("./../../../src/pages/ski-hoodies.js" /* webpackChunkName: "component---src-pages-ski-hoodies-js" */),
  "component---src-pages-ski-hoodies-thanks-js": () => import("./../../../src/pages/ski-hoodies/thanks.js" /* webpackChunkName: "component---src-pages-ski-hoodies-thanks-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-trip-hoodies-edgbaston-high-school-for-girls-rome-2024-js": () => import("./../../../src/pages/trip-hoodies/edgbaston-high-school-for-girls-rome-2024.js" /* webpackChunkName: "component---src-pages-trip-hoodies-edgbaston-high-school-for-girls-rome-2024-js" */),
  "component---src-pages-trip-hoodies-js": () => import("./../../../src/pages/trip-hoodies.js" /* webpackChunkName: "component---src-pages-trip-hoodies-js" */),
  "component---src-pages-trip-hoodies-tadcaster-grammar-school-battlefields-t-shirts-2024-js": () => import("./../../../src/pages/trip-hoodies/tadcaster-grammar-school-battlefields-t-shirts-2024.js" /* webpackChunkName: "component---src-pages-trip-hoodies-tadcaster-grammar-school-battlefields-t-shirts-2024-js" */),
  "component---src-pages-trip-hoodies-thanks-js": () => import("./../../../src/pages/trip-hoodies/thanks.js" /* webpackChunkName: "component---src-pages-trip-hoodies-thanks-js" */),
  "component---src-templates-archived-shops-js": () => import("./../../../src/templates/ArchivedShops.js" /* webpackChunkName: "component---src-templates-archived-shops-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/Post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-shop-js": () => import("./../../../src/templates/Shop.js" /* webpackChunkName: "component---src-templates-shop-js" */),
  "component---src-templates-shopv-2-js": () => import("./../../../src/templates/Shopv2.js" /* webpackChunkName: "component---src-templates-shopv-2-js" */),
  "component---src-templates-shopv-3-js": () => import("./../../../src/templates/Shopv3.js" /* webpackChunkName: "component---src-templates-shopv-3-js" */)
}

